@import 'theme';

nz-page-header {
  background-color: white !important;
}

::ng-deep nz-content > * {
  flex: 1;
}

svg[data-transparent] {
  fill: transparent;
}

nz-content {
  & > div {
    height: 100%;
  }
}
